import React, { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import "./HomeLocation.css";
import { API_BASE_URL } from "./constants";

const WorkLocation = () => {
  const [selectedOffice, setSelectedOffice] = useState("Bangalore Office");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!selectedOffice) {
      setError("Please select a work location.");
      return;
    }

    const token = localStorage.getItem("token");
    setMessage("");
    setError("");

    try {
      const orderResponse = await fetch(`${API_BASE_URL}/orders`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const orderResult = await orderResponse.json();

      if (orderResponse.ok && orderResult.orders.length > 0) {
        navigate("/orders");
        return;
      }

      const response = await fetch(`${API_BASE_URL}/set_address`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          work_address: selectedOffice,
        }),
      });

      if (response.ok) {
        setMessage("Work address saved successfully!");
        navigate("/dashboard");
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      setError("An error occurred while saving the work address.");
    }
  };

  return (
    <div className="location-page">
      <div className="location-container">
        <div className="location-form">
          <h2>Consent</h2>
          <p>
            I hereby consent to share my personal information with the company
            for official purposes only. I understand that my information will be
            kept confidential and used solely for company-related activities.
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <input
              type="checkbox"
              id="consentCheckbox"
              checked={isConsentGiven}
              onChange={(e) => setIsConsentGiven(e.target.checked)}
              style={{ marginRight: "10px", marginTop: "40px" }}
            />
            <label
              htmlFor="consentCheckbox"
              style={{ color: "black", marginTop: "25px" }}
            >
              I give my consent
            </label>
          </div>

          {error && <p className="error-message">{error}</p>}

          {isConsentGiven && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={handleSubmit} className="submit-button">
                Submit <ArrowForwardIcon />
              </button>
            </div>
          )}

          {message && <p className="success-message">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default WorkLocation;
