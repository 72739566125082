import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import "./Checkout.css";
import { API_BASE_URL } from "./constants";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const balance = location.state?.balance || 0;
  const cart = location.state?.cart || [];
  const [step, setStep] = useState(1);
  const [homeFirstName, setHomeFirstName] = useState("");
  const [homeLastName, setHomeLastName] = useState("");
  const [homeEmail, setHomeEmail] = useState("");
  const [homePhoneNumber, setHomePhoneNumber] = useState("");
  const [homeAltNumber, setHomeAltNumber] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [homeCity, setHomeCity] = useState("");
  const [homeState, setHomeState] = useState("");
  const [homeZipCode, setHomeZipCode] = useState("");
  const [isHomeLocation, setIsHomeLocation] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [workOffice, setWorkOffice] = useState("Hyd Office");
  const [workPhoneNumber, setWorkPhoneNumber] = useState("");
  const [deliveryDetails, setDeliveryDetails] = useState(
    "December 25th’ 2024 - January 10th’ 2025"
  );

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/profile`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUserDetails(data);
        setHomeFirstName(data.home_first_name || "");
        setHomeLastName(data.home_last_name || "");
        setHomeEmail(data.email || "");
        setHomePhoneNumber(data.home_phone_number || "");
        setHomeAltNumber(data.home_alternate_phone_number || "");
        setHomeAddress(data.home_address || "");
        setHomeCity(data.home_city || "");
        setHomeState(data.home_state || "");
        setHomeZipCode(data.home_zip_code || "");
      } else {
        console.error("Failed to fetch user details");
      }
    };

    fetchUserDetails();
  }, []);

  const handleNext = (event) => {
    event.preventDefault();
    console.log(isHomeLocation);
    const fields = isHomeLocation
      ? [
          homeFirstName,
          homeLastName,
          homeEmail,
          homePhoneNumber,
          homeAddress,
          homeCity,
          homeState,
          homeZipCode,
        ]
      : [homeFirstName, homeLastName, workOffice]; //removed homephonenumber field

    if (fields.some((field) => field.trim() === "")) {
      alert("Please fill in all the delivery details fields.");
      return;
    }

    // if (homePhoneNumber.length < 10 || homePhoneNumber.length > 10) {
    //   alert("Phone number is incorrect");
    //   return;
    // }
    // if (homeAltNumber && homeAltNumber.length !== 10) {
    //   alert("Alternate phone number incorrect");
    //   return;
    // }

    // if (homeZipCode.length < 6 || homeZipCode.length > 6) {
    //   alert("Pincode is incorrect");
    //   return;
    // }

    setStep(2);
  };

  const handleClearCart = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${API_BASE_URL}/clear_cart`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.error("Failed to clear cart");
      }
    } catch (error) {
      console.error("An error occurred while clearing the cart:", error);
    }
  };

  const handleResetBalance = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${API_BASE_URL}/reset_balance`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.error("Failed to reset balance");
      }
    } catch (error) {
      console.error("An error occurred while resetting the balance:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("Processing your purchase...");

    const token = localStorage.getItem("token");

    // Separate products and bundles along with their quantities
    const productIds = cart
      .filter((item) => item.id !== undefined && item.bundle_id === undefined) // Only products
      .map((item) => item.id);
    const productQuantities = cart
      .filter((item) => item.id !== undefined && item.bundle_id === undefined) // Only products
      .map((item) => item.quantity);

    const bundleIds = cart
      .filter((item) => item.bundle_id !== undefined) // Only bundles
      .map((item) => item.bundle_id);
    const bundleQuantities = cart
      .filter((item) => item.bundle_id !== undefined) // Only bundles
      .map((item) => item.quantity);

    // Format selected attributes to include product or bundle IDs
    const selected_attribute = [];

    cart.forEach((item) => {
      if (item.bundle_id !== undefined) {
        // For bundles, parse selected attributes correctly
        let bundleAttributes = {};
        try {
          bundleAttributes = JSON.parse(item.selected_attribute); // Assuming this returns an object
        } catch (error) {
          console.error("Error parsing bundle selected attributes:", error);
        }
        // Add the selected attribute for the bundle in the expected format
        selected_attribute.push({ [item.bundle_id]: bundleAttributes });
      } else if (item.id !== undefined) {
        // For products, ensure the attribute is formatted correctly
        selected_attribute.push({ [item.id]: item.selected_attribute });
      }
    });

    // Construct the request body in the expected format
    const requestBody = {
      product_ids: productIds,
      product_quantities: productQuantities,
      bundle_ids: bundleIds,
      bundle_quantities: bundleQuantities,
      selected_attribute: selected_attribute,
      address: isHomeLocation
        ? `${homeAddress}, ${homeCity}, ${homeState}, ${homeZipCode}, ${homePhoneNumber} , ${homeAltNumber}`
        : `${workOffice}, ${workPhoneNumber}`,
      delivery_details: deliveryDetails,
    };

    // console.log("Request Body: ", JSON.stringify(requestBody, null, 2)); // Pretty print for easier debugging

    try {
      const response = await fetch(`${API_BASE_URL}/buy`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody), // Send the formatted body
      });
      // console.log(token);

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(data.message);
        await handleClearCart();
        await handleResetBalance();
        setTimeout(() => {
          navigate("/orders", { replace: true });
          localStorage.setItem("hasPlacedOrder", true);
        }, 2000);
      } else {
        const errorData = await response.json();
        setSuccessMessage(errorData.error);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      setSuccessMessage("An error occurred during the purchase.");
    }
  };

  const calculateTotal = () => {
    const subtotal = cart.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    const shipping = 499;
    return subtotal + shipping;
  };

  const disableArrowKeys = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  return (
    <div className="checkout-page">
      <Navbar cartItemCount={cart.length} userBalance={balance} />
      <div className="checkout-steps">
        <div className="step-container">
          <span className={`step ${step === 1 ? "active-step" : ""}`}>1</span>
          <span className="step-label">Delivery</span>
        </div>
        <div className="step-container">
          <span className={`step ${step === 2 ? "active-step" : ""}`}>2</span>
          <span className="step-label">Review</span>
        </div>
      </div>

      <div className="checkout-content">
        {step === 1 && (
          <form onSubmit={handleNext}>
            <h2>Delivery Details</h2>
            {/* <p>
              <span style={{ color: "red" }}>Note:</span> We anticipate your
              order will arrive between{" "}
              <span style={{ background: "yellow" }}>
                December 25th’ 2024 - January 10th’ 2025.
              </span>
            </p>
            <p>
              To ensure successful delivery, please have someone available at
              the given address to receive the package.
            </p>
            <br />
            <h3 style={{ fontSize: "18px" }}>Important:</h3>
            <li style={{ textAlign: "center" }}>
              Undelivered packages will NOT be re-shipped.{" "}
            </li>
            <li style={{ textAlign: "center" }}>
              Googlers are responsible for making their own arrangements to
              collect returned packages.
            </li>
            <br />
            <p>
              If you are unavailable, select delayed delivery option i.e to
              receive parcel between January 15th’ 2025 - January 30th’ 2025{" "}
            </p> */}
            <div className="location-choose">
              <label>
                {/* <input
                  type="radio"
                  checked={isHomeLocation}
                  onChange={() => {
                    setIsHomeLocation(true);
                    setHomeAddress(userDetails.home_address || "");
                  }}
                /> */}
                {/* Delivery Details */}
              </label>
              {/* <label>
                <input
                  type="radio"
                  checked={!isHomeLocation}
                  disabled
                  onChange={() => {
                    setIsHomeLocation(false);
                    setHomeAddress("");
                  }}
                />
                Work Location
              </label> */}
            </div>

            {isHomeLocation && (
              <>
                <input
                  type="text"
                  placeholder="First Name"
                  value={homeFirstName}
                  onChange={(e) => setHomeFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={homeLastName}
                  onChange={(e) => setHomeLastName(e.target.value)}
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={homeEmail}
                  onChange={(e) => setHomeEmail(e.target.value)}
                  required
                />
                <input
                  type="number"
                  onKeyDown={disableArrowKeys}
                  placeholder="Phone Number"
                  value={homePhoneNumber}
                  onChange={(e) => setHomePhoneNumber(e.target.value)}
                  required
                />
                <input
                  type="number"
                  onKeyDown={disableArrowKeys}
                  placeholder="Alt Phone Number"
                  value={homeAltNumber}
                  onChange={(e) => setHomeAltNumber(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Address"
                  value={homeAddress}
                  onChange={(e) => setHomeAddress(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="City"
                  value={homeCity}
                  onChange={(e) => setHomeCity(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="State"
                  value={homeState}
                  onChange={(e) => setHomeState(e.target.value)}
                  required
                />
                <input
                  type="number"
                  onKeyDown={disableArrowKeys}
                  placeholder="Zip Code"
                  value={homeZipCode}
                  onChange={(e) => setHomeZipCode(e.target.value)}
                  required
                />
              </>
            )}

            {!isHomeLocation && (
              <>
                <input
                  type="text"
                  placeholder="First Name"
                  value={homeFirstName}
                  onChange={(e) => setHomeFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={homeLastName}
                  onChange={(e) => setHomeLastName(e.target.value)}
                  required
                />
                {/* <input
                  type="text"
                  placeholder="Name on the luggage tag"
                  value={workPhoneNumber}
                  maxLength={14}
                  onChange={(e) => setWorkPhoneNumber(e.target.value)}
                  required
                /> */}
                {/* <select
                  value={workOffice}
                  onChange={(e) => setWorkOffice(e.target.value)}
                  required
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <option value="Hyd Office">Hyderabad Office</option>
                  <option value="Bglr Office">Bangalore Office</option>
                </select> */}
              </>
            )}
            {/* <label htmlFor="delivery-date">Select Delivery Date Range:</label> */}
            {/* <select
              id="delivery-date"
              value={deliveryDetails}
              onChange={(e) => setDeliveryDetails(e.target.value)}
            >
              <option value="December 25th’ 2024 - January 10th’ 2025">
                December 25th’ 2024 - January 10th’ 2025
              </option>
              <option value="January 15th’ 2025 - January 30th’ 2025">
                January 15th’ 2025 - January 30th’ 2025
              </option>
            </select> */}
            <button type="submit">Next</button>
          </form>
        )}

        {step === 2 && (
          <div className="confirmation">
            <h2>Order Confirmation</h2>
            <div className="invoice">
              {/* <h3>Billing Information</h3> */}
              <p>
                <strong>First Name:</strong> {homeFirstName}
              </p>
              <p>
                <strong>Last Name:</strong> {homeLastName}
              </p>
              <p>
                <strong>Email:</strong> {homeEmail}
              </p>
              {/* <p>
                <strong>Name on bag tag:</strong> {workPhoneNumber}
              </p> */}
              {/* <p>
                <strong>Alt Number:</strong> {homeAltNumber}
              </p>
              <p>
                <strong>Address:</strong>{" "}
                {isHomeLocation ? homeAddress : workOffice}
              </p>
              <p>
                <strong>City:</strong> {homeCity}
              </p>
              <p>
                <strong>State:</strong> {homeState}
              </p>
              <p>
                <strong>Zip Code:</strong> {homeZipCode}
              </p>
              <p>
                <strong>Delivery date:</strong> {deliveryDetails}
              </p> */}

              <h3>Ordered Items</h3>
              <table className="invoice-table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    {/* <th>Price</th> */}
                    {/* <th>Total</th> */}
                  </tr>
                </thead>
                <tbody>
                  {cart.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <div>
                          <h3>{item.name}</h3>
                          <p>{item.description}</p>
                        </div>
                      </td>
                      <td>{item.quantity}</td>
                      {/* <td>{item.price} points</td> */}
                      {/* <td>{item.price * item.quantity} points</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* <h3>
                Total Amount: {calculateTotal()} points (Includes 499 points for
                shipping)
              </h3> */}
              <button onClick={handleSubmit}>Confirm Order</button>
            </div>
          </div>
        )}
        {successMessage && (
          <p className="success-message">Your order is complete</p>
        )}
      </div>
    </div>
  );
};

export default Checkout;
